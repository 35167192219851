import Swiper, { Autoplay, Pagination } from 'swiper/swiper.esm.js';

Swiper.use([Autoplay, Pagination])

new Swiper('.google-reviews-swiper', {
    slidesPerView: 1,
    longSwipes: false,
    loop: true,
    spaceBetween: 60,
    resistance: false,
    grabCursor: true,
    breakpoints: {
        1500: {
            slidesPerView: 2,
            spaceBetween: 61,
        }
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },
    autoplay: {
        delay: 2000,
    }
})
